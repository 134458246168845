/**
 * 공통 함수 모음
 */

import { clearSession, getSession } from "../lib/session";
import { Confirm } from "./type";

// Selector
const logout = document.querySelector("#logout-button") as HTMLButtonElement;
const confirm = document.querySelector(".confirm") as HTMLDivElement;

// State

interface State {
  confirm: Confirm;
}

const state: State = {
  confirm: {
    show: false,
    title: "",
    message: "",
    onClick: () => {},
    onClose: undefined,
  },
};

// useEffect
document.addEventListener("DOMContentLoaded", () => {
  if (window.location.pathname === "/") {
    const session = getSession();

    if (session) {
      window.location.replace("/live-list");
    }

    return;
  }

  // 로그아웃 버튼 클릭
  logout?.addEventListener("click", () => {
    clearSession();
    window.location.href = "/";
  });
});

// Methods
export const toggleModal = () => {
  if (!confirm) {
    console.warn(".modal not found");
    return;
  }

  const next = !state;

  if (next) {
    confirm.classList.add("open");
  } else {
    confirm.classList.remove("open");
  }

  state.confirm.show = true;
};
